#ar {
  .ar {
    .section-info {
      line-height: 25px;
      margin-bottom: 5px;
    }
  }
  .info-ar {
    display: flex;
    align-items: center;
    display: none;
    // border: 1px solid red;
    margin-top: 5px;

    @media screen and (max-width: 480px) {
      display: block;
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 7px;
      @media screen and (max-width: 480px) {
        // margin-bottom: -20px;;
      }
    }
    
  }
  .info {
    display: block;
    @media screen and (max-width: 480px) {
      display: none;
    }

    .scan-qr {
      font-weight: 700;
      background-image: var(--gradient-text);
      background-size: 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-text-fill-color: transparent;  
      cursor: pointer;
    }
  }
}