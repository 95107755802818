#services {
  
  .card-wrapper {
    border: var(--test1);
    padding: 0 24px;
    max-width: var(--max-width);
    margin: 0 auto; 
  }
  .type-container {
    margin-top: 30px; 
    display: flex;
    flex-direction: column;
    .type {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .description {
      // font-size: 20px;
    }
  }
 
  .card-container-services {
    // border: 1px solid red;
    // width: 100%;
    // height: 200px;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    position: relative;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    .basic, .standard, .premium {
      // border: 1px solid yellow;
      width: 100%;
      // height: 500px;
      border-radius: 30px;
      transition: all 300ms linear;
      // position: relative;
      padding: 30px;
      box-sizing: border-box;
      flex: 0 0 calc(33.33% - 15px);

      @media screen and (max-width: 880px) {
        flex-wrap: wrap;
        flex-basis: calc(50% - 10px);
      }

      .card-content {
        // border: 1px solid yellow;
        height: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;

        .title-service {
          font-size: 50px;
          font-weight: 700;
          background-image: var(--gradient-text);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          margin-right: 0;
        }
        .subtitle-service {
          // transition: all 300ms linear;
          margin-right: 0;
        }
        .content-service {
          // border: 1px solid yellowgreen;
          width: 100%;
          height: 100%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0ms linear !important;

          .content-service-wrapper {
            // border: 1px solid blue;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              // border: 1px solid red;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 5px;
            }
          }
          
        }
      }

    }
    .basic, .standard, .premium {
      // border: 1px solid red;
      position: relative;
      overflow: hidden;

      .overlay-container {
        // border: 1px solid red;
        // background-color: yellow;
        width: 100%;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .overlay-service {
        // border: 1px solid red;
        position: absolute;
        border-radius: 30px;
        left: 0;
        height: 130px;
        width: 100%;
        z-index: 10;
        -webkit-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transform: translateY(100%);
        bottom: -3px;
        display: flex;
        justify-content: center;
        // transition: all 300ms linear;
        // background-color: red;


        .button-container{
          box-shadow: none !important;
          // border: 1px solid yellow;
          // padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .card-button {
            background-color: var(--main-bg);
            color: var(--main-color);
            border: none;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            font-size: 16px;
            border-radius: 20px;
            padding: 10px 35px;
            transition: all 300ms linear;
            // width: 150px;
            // max-width: 200px;
          }
        
        }
      }
    }
    .basic:hover .overlay-service, .standard:hover .overlay-service, .premium:hover .overlay-service{
      transform: translateY(0);
    }
    .standard {
      background-image: var(--gradient-45-deg);
      .title-service-standard {
        font-size: 50px;
        font-weight: 700;
        margin-right: 0;
      }
      .subtitle-service-standard {
        margin-right: 0;
      }
    }
    
  }
}