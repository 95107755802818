#about {
  .card-wrapper {
    border: var(--test1);
    padding: 0 24px;
    max-width: var(--max-width);
    margin: 0 auto; 
  }
  .card-container-about {
    // border: 1px solid red;
    width: 100%;
    // height: 200px;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    .each-card {
      border-radius: 30px;
      transition: all 300ms linear;
      padding: 20px;
      box-sizing: border-box;
    }
    .info {
      flex: 1;
      min-height: 683px;
      // padding: 0;
      .img-container {
        // border: 1px solid red;
        // height: 273px;
        // box-sizing: border-box;
      }
      img {
        width: 100%;
        // height: 100%;
        border-radius: 50%;
      }
      .info-details {
        // border: 1px solid red;
        padding-top: 30px;
        text-align: center;
        line-height: 25px;
        span {
          margin: 0;
        }
        .hr {
          transition: all 300ms linear;
          width: 100%;
          // border-top: 1px solid rgba(0,0,0,0.09);
          margin: 30px 0;
        }
        .me {
          margin-bottom: 30px;
          font-size: 30px;
          font-weight: 700;
          background-image: var(--gradient-text);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;
          // width: unset;
        }
      }
      .linkedin {
        // background-color: var(--main-bg);
        color: var(--main-color);
        border: none;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        // font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        border-radius: 20px;
        padding: 10px 20px;
        transition: all 300ms linear;
        width: 100%;
        margin-top: 30px;
      }
      
    }
    .overview {
      flex: 3;
      .parent {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: 20px;

        @media screen and (max-width: 880px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(8, 1fr);
        }
        }
        
        .div1, .div2, .div3, .div4, .div5, .div6, .div7, .div8 {
          // border: 1px solid red;
          // background-color: rgb(49, 49, 49);
          // height: 300px;
          position: relative;
          transition: all 300ms linear;
          
          @media screen and (max-width: 480px) {
            height: 200px;
          }
          @media screen and (max-width: 880px) {
            height: 200px;
          }
        }
        

        div {
          // border: 1px solid red;
          border-radius: 20px;
          width: 100%;
          height: 100%;
        }
        
        
        .div1 { grid-area: 1 / 1 / 3 / 3; }
        .div2 { grid-area: 3 / 1 / 5 / 3; }
        .div3 { grid-area: 5 / 1 / 7 / 3; }
        .div4 { grid-area: 1 / 3 / 3 / 5; }
        .div5 { grid-area: 3 / 3 / 5 / 5; }
        .div6 { grid-area: 1 / 5 / 3 / 7; }
        .div7 { grid-area: 3 / 5 / 5 / 7; }
        .div8 { grid-area: 5 / 3 / 7 / 7; }

        .div8 {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 80px;
            font-weight: 700;
            margin-right: 0;
            @media screen and (max-width: 880px) {
              font-size: 50px;
            }
          }
        }

        @media screen and (max-width: 880px) {
          .div1 { grid-area: 1 / 1 / 2 / 2; }
          .div2 { grid-area: 1 / 2 / 2 / 3; }
          .div3 { grid-area: 2 / 1 / 3 / 2; }
          .div4 { grid-area: 2 / 2 / 3 / 3; }
          .div5 { grid-area: 3 / 1 / 4 / 2; }
          .div6 { grid-area: 3 / 2 / 4 / 3; }
          .div7 { grid-area: 4 / 1 / 5 / 2; }
          .div8 { grid-area: 4 / 2 / 5 / 3; }
        }
        @media screen and (max-width: 480px) {
          .div1 { grid-area: 2 / 1 / 3 / 2; }
          .div2 { grid-area: 3 / 1 / 4 / 2; }
          .div3 { grid-area: 4 / 1 / 5 / 2; }
          .div4 { grid-area: 5 / 1 / 6 / 2; }
          .div5 { grid-area: 6 / 1 / 7 / 2; }
          .div6 { grid-area: 7 / 1 / 8 / 2; }
          .div7 { grid-area: 8 / 1 / 9 / 2; }
          .div8 { grid-area: 1 / 1 / 2 / 2; }
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: var(--gradient-45-deg);
          opacity: 0;
          transition: all 300ms linear;
          padding: 20px;
          box-sizing: border-box;
          // border: 1px solid red;


          .overlay-info {
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            
            .overlay-title {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 5px;

            }
          }
          
        }
        .each-div:hover .overlay {
          opacity: 1;
          z-index: 5;
        }
        .each-div:hover svg {
          opacity: 0;
        }

        .icons-container {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid red;
          box-sizing: border-box;
          object-fit: cover;
          position: relative;
          overflow: hidden;
          div {
            position: absolute;
          }
          svg {
            position: absolute; /* Position the image absolutely */
            top: 0;
            left: 0;
            width: 100%; /* Set the image width to 100% */
            height: auto; /* Maintain image aspect ratio */
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            opacity: 1;
            transition: all 300ms linear;
            path {
              transition: all 300ms linear;
            }
            rect {
              transition: all 300ms linear;
            }
          }
        }

    }
  }
}