#home {

    .banner {
      overflow: hidden;
      // border: 1px solid red;
      height: 70vh;
      padding: 0 !important;
      background-image: var(--gradient-45-deg);
      color: white;
      font-size: 100px;
      // font-family: "Roboto";

      @media screen and (max-width: 480px) {
        height: 70vh;
        z-index: 10 !important;
        position: relative;
      }

      .banner-container {
        @media screen and (max-width: 480px) {
          flex-direction: column;
          padding: 0;
        }

        margin-top: 30px;
        // border: 1px solid yellow;
        height: 100%;
        // padding: 0 30px;
        width: 100%;
        // margin: 0 auto; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .mobile-padding {
          @media screen and (max-width: 480px) {
            background-color: aquamarine;
            width: 100%;
            height: 120px;
          }
        }
        .logo {
          svg {
            height: 150px;
            transition: all 300ms linear;
            path {
              transition: all 300ms linear;
            }
            @media screen and (max-width: 480px) {
              height: 150px;
            }
          }
        }
        .motto {
          font-size: 80px;
          font-weight: 700;
          transition: all 300ms linear;
          text-align: center;
          span {
            margin-right: 0;
          }
          @media screen and (max-width: 480px) {
            font-size: 50px;
            text-align: center;
            line-height: 50px;
          }
        }

      }
    }

    


    .projects {

      
    }
    .ar {
      .section-info {
        line-height: 25px;
        margin-bottom: 5px;
      }
      .info {
        display: block;
        @media screen and (max-width: 480px) {
          display: none;
        }

        .scan-qr {
          font-weight: 700;
          background-image: var(--gradient-text);
          background-size: 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-text-fill-color: transparent;  
          cursor: pointer;
        }
      }
      .info-ar {
        display: flex;
        align-items: center;
        display: none;
        // border: 1px solid red;
        // margin-top: 5px;
    
        @media screen and (max-width: 480px) {
          display: block;
          display: flex;
          align-items: center;
        }
    
        span {
          margin-left: 7px;
          @media screen and (max-width: 480px) {
            // margin-bottom: -20px;;
          }
        }
        
      }
    }
}




