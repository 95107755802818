#contact {
  // @media screen and (max-width: 480px) {
  //   height: auto;
  // }
  height: var(--footer-adjustment);

  .contact {
    @media screen and (max-width: 480px) {
      .pagetopMargin {
        display: none;
      }
    }
    height: 100%;
    // border: 1px solid blue;

    .form {
      @media screen and (max-width: 480px) {
        flex-direction: column;
        // height: 500px;
      }
      // height: 500px;
      display: flex;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .left {
        // border: 1px solid yellow;
        height: 100%;
        // width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .idea {
          text-align: center;
          @media screen and (max-width: 480px) {
            font-size: 50px;
            
          }
          // font-family: 'Roboto';
          font-size: 100px;
          font-weight: 700;
          transition: all 300ms linear;
          margin-right: 0 !important;
        }
        .touch {
          font-size: 30px;
        }
        .form-button {
          background-color: var(--main-bg);
          color: var(--main-color);
          border: none;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          cursor: pointer;
          // font-family: "Roboto";
          font-size: 16px;
          font-weight: 400;
          border-radius: 20px;
          padding: 10px 20px;
          margin: 0 10px 0 10px;
          transition: all 300ms linear;
          margin-bottom: 10px;
          width: 150px;
          max-width: 200px;
          margin-top: 30px;
        }
      }
    }
  }
}