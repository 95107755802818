#pricing {
  .title {
    margin-bottom: 30px;
  }
  .dropdown-container {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    .wrapper {
      margin: 0;
      padding: 0;
      @media screen and (max-width: 1050px) {
        padding: 0 24px;
      }
    }
  }
  .dropdown-wrapper {
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    border: var(--test1);
    // padding: 0 24px;
    max-width: var(--max-width);
    margin: 0 auto; 
    height: 100%;
  }
  .each-service {
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    // margin-top: 30px;
    padding: 30px;
    border-radius: 30px;
    transition: all 300ms linear;
    @media screen and (max-width: 480px) {
      padding: 20px;
    }

    .title-control {
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      span {
        margin-right: 0;

        span {
          margin-right: 0;
        }
      }

      .title-name {
        font-size: 30px;
        font-weight: 700;
      }

      .contol-icon {
        font-size: 40px;
      }
    }

    .price-container {
      // border: 1px solid red;
      margin-top: 30px;
      .table-row {
        // border: 1px solid yellow;
        // height: 50px;
        display: flex;
        flex-direction: row;
        position: relative;
        div {
          // border: 1px solid blue;
          // width: 100%;
        }

        .left {
          flex: 1;
          // border: 1px solid pink;
          @media screen and (max-width: 480px) {
            // display: none;
            position: absolute;
            top: -100px;
          }
          @media screen and (max-width: 880px) {
            // display: none;
            position: absolute;
            top: -100px;
          }

          .info {
            margin-left: 10px;
            cursor: help;
            @media screen and (max-width: 480px) {
              width: 15px;
              height: 15px;
            }
          }
          .material-symbols-outlined {
            font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
          }

          .table-cell{
            // border: 1px solid yellowgreen;
            width: 100%;
            height: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
            @media screen and (max-width: 480px) {
              height: 8px;
              justify-content: flex-start;
            }
            @media screen and (max-width: 880px) {
              height: 8px;
              justify-content: flex-start;
            }

            .blank-name {
              @media screen and (max-width: 480px) {
                display: none !important;
              }
            }

            .feature-name {
              font-size: 20px;
              font-weight: 700;
              margin-right: 0;
              @media screen and (max-width: 480px) {
                font-size: 16px;
              }
            }

            svg {
              margin-left: 10px;
              path {
                transition: all 300ms linear;
              }
            }
          }
          .table-header {
            height: 50px;
          }


          .window-top-left {
            height: 50px;
            display: none;
            @media screen and (max-width: 480px) {
              display: block;
            }
            @media screen and (max-width: 880px) {
              display: block;
            }
          }

        }
        .right {
          flex: 3;
          // border: 1px solid blue;
          z-index: 5;
          display: flex;
          flex-direction: column;
          width: 100px; /* Set your desired width */
          // min-width: 500px;
          white-space: nowrap; /* Prevent text from wrapping */
          overflow-x: auto; /* Enable horizontal scrolling */
          // border: 1px solid #ccc; /* Optional border for clarity */

          .right-row {
            width: 100%;
            display: flex;
            flex-direction: row;
          }
          .window-top-right {
            height: 50px;
            display: none;
            @media screen and (max-width: 480px) {
              display: block;
            }
            @media screen and (max-width: 880px) {
              display: block;
            }
          }
          .table-cell{
            // border: 1px solid yellowgreen;
            width: 100%;
            height: 8px;
            display: flex;
            // justify-content: center;
            align-items: center;
            padding: 20px 0;
            @media screen and (max-width: 480px) {
              // width: 300px;
              min-width: 170px;
              // display: inline-block; 
            }
            .header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-items: center;
              span {
                margin-right: 0;
              }
              .price {
                font-size: 50px;
                font-weight: 700;
                
                @media screen and (max-width: 480px) {
                  font-size: 30px;
                }
              }
              .type {
                font-weight: 700;
                font-size: 20px;
                transition: all 1s linear;
              }
            }
            .desc {
              margin-right: 0;
            }
          }
          .table-header {
            height: 50px;
          }
          .cancel {
            display: none;
            @media screen and (max-width: 480px) {
              display: block;
            }
            @media screen and (max-width: 880px) {
              display: block;
            }
          }
        
        }

      }

      .hr {
        width: 100%;
        margin: 30px 0;
        transition: all 300ms linear;
      }
    }
  }
}