#terms {
  .terms-content {
    // border: 1px solid red;
    width: 100%;
    margin-top: 30px;
    .terms-title {
      font-size: 30px;
      font-weight: 700;
      // margin-bottom: 5px;
    }
    .terms-info {
      // text-align: justify;
      // color: red;
      display: flex;
      flex-direction: column;
    }
    strong {
      transition: all 300ms linear;
    }
  }
  ul {
    margin: 0;
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }
}
